<template>
	<div class="home-box">
		<!-- 顶部导航 -->
		<top-nav currTab="1"  v-model:banner="banner" height="800px">

			<div class="title-image">
				<img src="../assets/images/about-us/title.png" alt="">
			</div>

		</top-nav>


		<!-- 内容 -->
    <div class="content-box">

      <!-- 标题 -->
      <div class="content-title">
        <!-- 标题 -->
        <div class="title-text FONT_SHSB">
          关于我们
        </div>

        <!-- 内容 -->
        <div class="title-content FONT_SHSR">
					深圳方合科技有限公司（以下简称公司）是移动互联网信息科技企业，2014年成立，注册地深圳，公司以服务为宗旨，搭建新能源相关信息交流平台，为新能源材料企业提供线上信息咨询服务，旗下产品有工解APP等，是个专业性的服务平台，致力于为用户提供有价值的专业性的信息。
        </div>
      </div>

      <!-- 内容 -->
      <div class="view-content">

				<!-- 图片 -->
				<div class="content-show">
					<img src="../assets/images/about-us/line-1.png" alt="">
				</div>
				

				<!-- 联系 -->
				<div class="contact-box">

					<div class="left">
						<p class="title FONT_SHSB">联系我们</p>
						<p class="company FONT_SHSR">深圳市方合科技有限公司</p>
						<p class="contact-text FONT_SHSR">地址：深圳市龙华区龙华街道龙园社区花园新村1号406</p>
						<p class="contact-text FONT_SHSR">电话：15816858652</p>
						<p class="contact-text FONT_SHSR">电子邮件：zhaoliu.sheng@163.com</p>
					</div>
					
					<div class="right">
						<img src="../assets/images/about-us/contact.png" alt="">
					</div>

				</div>

      </div>

    </div>


		<bottom-nav></bottom-nav>
	</div>
</template>

<script>
import topNav from "@/components/topNav";
import bottomNav from "@/components/bottomNav";

import {ref} from 'vue'

export default {
	components: {
		topNav,
		bottomNav,
	},
	setup() {

		// banner 
		const banner = require('@/assets/images/about-us/banner.png')

		return {
			banner
		};
	},
};
</script>

<style lang="scss" scoped>


.WP_{
	width: 1300px;
	padding: 50px calc(50% - 650px);
}

.home-box {
	.title-image{
		height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
	}

	// 描述
	.content-box{
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: center;
		margin: 50px auto;
		width: 100%;

		// 标题
		.content-title{
			background-image: url(../assets/images/about-us/content-title.png);
			background-position: center 40px;
			background-size: cover;
			background-repeat: no-repeat;
			@extend .WP_;
			// width: 1300px;
			// padding: 50px calc(50% - 650px);

			.title-text{
				font-size: 50px;
				text-align: center;
			}

			.title-content{
				font-size: 20px;
				color: #6A6A6A;
				margin: 100px 0;
				line-height: 30px;
				margin-bottom: 0;
			}
		}

		.view-content{
			width: 100%;

			.content-show{
				@extend .WP_;

				img{
					width: 100%;
				}
			}

			.contact-box{
				@extend .WP_;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 100px;
				
				.left{
					.title{
						font-size: 24px;
						line-height: 60px;
					}
					.company{
						font-size: 40px;
						line-height: 60px;
					}
					.contact-text{
						color: #6A6A6A;
						font-size: 20px;
						line-height: 80px;
					}

				}

			}
		}

	}
}
</style>